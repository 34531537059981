<script setup lang="ts"></script>

<template>
  <svg
    width="216"
    height="43"
    viewBox="0 0 216 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="promobox-flag absolute -top-3 -left-3"
    aria-hidden="true"
  >
    <path
      d="M3.4375 32.3723C0.9375 27.8723 -0.869801 25.5274 0.894854 19.7559L10.8874 20.1653C13.1628 20.2586 14.5395 22.7798 13.6637 24.882C12.1796 28.4445 11.4375 31.8855 11.4375 34.3723V42.8723C9.9375 41.7056 5.50248 36.0892 3.4375 32.3723Z"
      fill="#208664"
    ></path>
    <path
      d="M0.240604 22.3661L2.17704 13.4081C3.86833 5.58415 10.7886 0 18.7933 0H212.696C214.636 0 216.066 1.81289 215.614 3.69911L210.821 23.6991C210.498 25.0485 209.291 26 207.904 26H3.17288C1.26073 26 -0.163409 24.2351 0.240604 22.3661Z"
      fill="#10B981"
    ></path>
  </svg>
</template>

<style scoped></style>
